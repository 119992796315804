import styled from 'styled-components';
import * as colors from '../colors';
import {
    downloadArrowBlueberry,
    downloadArrowBlueberryDark,
    downloadArrowWhite,
    downloadBarBlueberry,
    downloadBarBlueberryDark,
    downloadBarWhite,
    externalArrowBlueberry,
    externalArrowBlueberryDark,
    externalArrowWhite,
    externalDotBlueberry,
    externalDotBlueberryDark,
    externalDotWhite,
} from '../icons';

export const splitGradient = (leftColor, rightColor) =>
    `linear-gradient(to right, ${leftColor} 0%, ${leftColor} 50%, ${rightColor} calc(50% + .1px), ${rightColor} 100%);`;

const PrimaryButton = styled.a.attrs(props => ({
    'data-design-category': props.dataDesignCategory || props['data-design-category'],
}))`
    padding: 0;
    cursor: pointer;
    background-image: ${({ theme }) =>
        theme === 'light'
            ? splitGradient(colors.white, colors.mist)
            : splitGradient(colors.blueberry, colors.blueberryDark)};
    background-size: 201% 100%;
    background-origin: border-box;
    border: none;
    color: ${({ theme }) => (theme === 'light' ? colors.blueberry : colors.white)};
    display: flex;
    justify-content: center;
    align-items: stretch;
    transition: border-radius 0.2s ease-in-out, background-position 0.2s ease-in-out,
    color 0.2s ease-in-out;
    overflow: hidden;
    text-decoration: none;
    border-radius: 4px;
    text-align: center;
    margin: 0;

    > span {
        padding: 0.8em 22px 0.7em;
    }
    @media (hover:hover) {
        &:hover,
        &:active,
        &:focus {
            background-position: right center;
            color: ${({ theme }) => (theme === 'light' ? colors.blueberryDark : colors.white)};
        }
    }


    &[download] {
        &::after {
            display: block;
            content: '';
            width: 44px;
            background-image: ${({ theme }) =>
                theme === 'light'
                    ? `url(${downloadBarBlueberry}), url(${downloadArrowBlueberry})`
                    : `url(${downloadBarWhite}), url(${downloadArrowWhite})`};
            background-color: ${({ theme }) =>
                theme === 'light' ? colors.mist : colors.blueberryDark};
            background-repeat: no-repeat;
            background-position: center;
            background-size: 10px 12px;
        }
        @media (hover:hover) {
            &:hover {
                &::after {
                    animation-duration: 0.3s;
                    animation-name: download-animation;
                    background-image: ${({ theme }) =>
                        theme === 'light'
                            ? `url(${downloadBarBlueberryDark}), url(${downloadArrowBlueberryDark})`
                            : `url(${downloadBarWhite}), url(${downloadArrowWhite})`};
                }
            }
        }
    }

    @keyframes download-animation {
        0%,
        100% {
            background-position: center, center 50%;
        }

        50% {
            background-position: center, center calc(100% + 12px);
        }

        50.001% {
            background-position: center, center calc(0% - 12px);
        }
    }

    &[disabled] {
        background-image: none;
        background-color: ${colors.slate};
        pointer-events: none;
        color: ${colors.white};

        &[download]&::after {
            background-image: url(${downloadBarWhite}), url(${downloadArrowWhite});
            background-color: ${colors.concrete};
        }

        ${({ external }) =>
            external &&
            `&::after {
                background-image: url(${externalDotWhite}), url(${externalArrowWhite});
                background-color: ${colors.concrete};
            }`}
    }

    ${({ external, theme }) =>
        external &&
        `&::after {
            display: block;
            content: "";
            width: 44px;
            background-image: ${
                theme === 'light'
                    ? `url(${externalDotBlueberry}), url(${externalArrowBlueberry})`
                    : `url(${externalDotWhite}), url(${externalArrowWhite})`
            };
            background-color: ${theme === 'light' ? colors.mist : colors.blueberryDark};
            background-repeat: no-repeat;
            background-position: center;
            background-size: 10px 10px;
        }
        @media (hover:hover) {
            &:hover {
                &::after {
                    animation-duration: .4s;
                    animation-name: external-animation;
                    background-image: ${
                        theme === 'light'
                            ? `url(${externalDotBlueberryDark}), url(${externalArrowBlueberryDark})`
                            : `url(${externalDotWhite}), url(${externalArrowWhite})`
                    };
                }
            }
        }`}

    @keyframes external-animation {
        0%,
        100% {
            background-position: center, center 50%;
        }

        50% {
            background-position: center, calc(100% + 10px) calc(0% - 10px);
        }

        50.001% {
            background-position: center, calc(0% - 10px) calc(100% + 10px);
        }
    }
`;

export default PrimaryButton;
